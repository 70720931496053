import { ref } from "vue";
import { useRouter } from "vue-router";
import { pagesettles } from "@/api/userInfo";
import { showLoading, hideLoading } from "@/utils/utils"; // 引入封装的 loading 工具

export default {
  name: "depositHistory",

  setup() {
    const router = useRouter();
    const activeTab = ref("INR"); // 默认显示 INR Tab

    const loading = ref(false); // 控制加载状态

    const finished = ref(false); // 控制是否已加载完所有数据

    const items = ref([]); // 存储分页加载的数据

    const currentPage = ref(1); // 当前页码

    const pageSize = 10; // 每页数据条数

    const onClickLeft = () => history.back(); // 获取结算类型文本 结算类型0充值配额成功1订单入账结算


    const getStatusText = type => {
      const typeMapping = {
        "0": "Recharge quota successfully",
        "1": "Order accounting settlement",
        "2": "Rupee platform trading",
        "3": "Rupee Withdrawal Bank",
        "4": "User orders",
        "5": "Recharge rewards"
      };
      return typeMapping[type] || "未知类型";
    }; // 获取结算类型对应的颜色


    const getStatusColor = type => {
      const colorMapping = {
        "0": "rgb(47, 217, 17)",
        "1": "rgb(217, 27, 17)",
        "2": "rgb(255, 165, 0)",
        "3": "rgb(0, 123, 255)",
        "4": "rgb(220, 53, 69)",
        "5": "rgb(40, 167, 69)"
      };
      return colorMapping[type] || "#000";
    }; // 加载数据


    const loadData = async () => {
      showLoading("Loading..."); // 显示加载状态

      loading.value = true;

      try {
        // 调用 pagesettles 接口，根据 activeTab 加载不同币种数据
        const response = await pagesettles({
          currency: activeTab.value.toLowerCase(),
          current: currentPage.value,
          size: pageSize
        });

        if (response.code === 1) {
          const newItems = response.data.records;
          items.value.push(...newItems); // 将新数据添加到列表中
          // 判断是否已加载所有数据

          if (items.value.length >= response.data.total) {
            finished.value = true; // 标记为已加载完所有数据
          } else {
            currentPage.value++; // 增加页码，准备下次加载
          }
        } else {
          console.error("获取数据失败", response.msg);
        }
      } catch (error) {
        console.error("请求失败", error);
      }

      hideLoading(); // 隐藏加载状态

      loading.value = false; // 停止加载状态
    }; // 切换 Tab 时根据币种加载数据


    const switchTab = async tab => {
      activeTab.value = tab; // 更新当前的 Tab

      currentPage.value = 1; // 重置页码

      items.value = []; // 清空已有数据

      finished.value = false; // 重置完成状态

      await loadData(); // 加载新数据
    }; // 加载更多数据


    const onLoad = async () => {
      await loadData(); // 调用加载数据函数
    };

    return {
      activeTab,
      onClickLeft,
      onLoad,
      items,
      loading,
      finished,
      switchTab,
      getStatusText,
      getStatusColor
    };
  }

};